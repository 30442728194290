import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import MarketplaceAd from '../Entity/Marketplace/MarketplaceAd';
import MarketplaceLot from '../Entity/Marketplace/MarketplaceLot';
import MarketplaceAdResponse from '../../../api/Llasm/Marketplace/MarketplaceAdResponse';
import MarketplaceLotResponse from '../../../api/Llasm/Marketplace/MarketplaceLotResponse';
import {AxiosResponse} from 'axios';

class MarketplaceService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
    }

    public async fetchMarketplaceAdsFromApi(): Promise<MarketplaceAd[]> {
        // TODO remove once backend endpoint is implemented
        return [];

        const apiResponse: AxiosResponse<MarketplaceAdResponse[]> = await this.llasmApiV1Provider.get('/marketplace-ads');

        return apiResponse.data.map((marketplaceAdResponse: MarketplaceAdResponse): MarketplaceAd => {
            return MarketplaceAd.createFromMarketplaceAdResponse(marketplaceAdResponse);
        });
    }

    public async fetchMarketplaceLotsFromApi(): Promise<MarketplaceLot[]> {
        // TODO remove once backend endpoint is implemented
        return [];

        const apiResponse: AxiosResponse<MarketplaceLotResponse[]> = await this.llasmApiV1Provider.get('/marketplace-lots');

        return apiResponse.data.map((marketplaceLotResponse: MarketplaceLotResponse): MarketplaceLot => {
            return MarketplaceLot.createFromMarketplaceLotResponse(marketplaceLotResponse);
        });
    }
}

export default MarketplaceService;

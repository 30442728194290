import MarketplaceService from '../MarketplaceService';
import MarketplaceAd from '../../Entity/Marketplace/MarketplaceAd';
import MarketplaceAdFilter from '../../Entity/Marketplace/MarketplaceAdFilter';
import FormData from '../../Entity/Form/FormData';
import SpecialFilter from './SpecialFilter';
import Sorting from './Sorting';
import Filter from './Filter';
import InfoButtonOverlay from '../../Component/InfoButtonOverlay/InfoButtonOverlay';
import BootstrapIcon from '../../Component/Icon/BootstrapIcon';
import Card from '../../Component/Card/Card';
import Spinner from '../../../../components/Spinner';
import {useAppDispatch} from '../../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

const marketplaceService = new MarketplaceService(process.env.REACT_APP_LLASM_API_URL!);

const createMarketplaceAdFilter = (): MarketplaceAdFilter => {
    const marketplaceAdFilter: MarketplaceAdFilter = new MarketplaceAdFilter();

    marketplaceAdFilter.fundingAvailable = false;
    marketplaceAdFilter.onlyImmediatelyAvailable = false;

    return marketplaceAdFilter;
};

const MarketplaceAdOverviewPage = (): React.JSX.Element => {
    const [marketplaceAds, setMarketplaceAds] = useState<MarketplaceAd[]>();

    const [marketplaceAdFilterFormData, setMarketplaceAdFilterFormData] = useState<FormData<MarketplaceAdFilter>>({data: createMarketplaceAdFilter()});

    const [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Marktplatz'},
            ]
        });

        fetchMarketplaceAds();
    }, []);

    const fetchMarketplaceAds = async (): Promise<void> => {
        setMarketplaceAds(await marketplaceService.fetchMarketplaceAdsFromApi());
    };

    const toggleIsFilterExpanded = (): void => {
        setIsFilterExpanded(isFilterExpanded === false);
    };

    const onFilterSubmit = (): void => {
        // TODO implement
    };

    if (process.env.NODE_ENV === 'production') {
        return <></>;
    }

    if (marketplaceAds === undefined) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid">
            <div className="row align-items-center mb-4">
                <div className="col-12 col-md-6 d-flex align-items-center">
                    <Link to="/"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Marktplatz</h1>
                </div>
                <div className="col-md-6 d-none d-md-flex align-items-center justify-content-end px-4">
                    <InfoButtonOverlay title="Marktplatz" className="fs-5 ms-1 me-3">
                        <div className="mb-3">
                            Auf dem Marktplatz findest Du Flächenangebote, die sich klassischerweise nicht einfach mit
                            Nachnutzungskonzepten matchen lassen. Dies sind beispielsweise Kaufhäuser oder Mixed-Use
                            Flächen aber auch geförderte Angebote.
                        </div>
                        <div className="mb-3">
                            Auf dem Marktplatz kannst Du jederzeit nach passenden Objekten suchen und Dir die Details zu
                            einer Fläche anschauen. Ist diese für Dich interessant, kannst Du direkt über ein Kontaktformular
                            mit dem Anbieter der Fläche kommunizieren.
                        </div>
                    </InfoButtonOverlay>
                    <Link to="/marktplatz/inserate" className="btn btn btn-secondary text-nowrap">
                        Meine Inserate
                    </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 mb-3">
                    <SpecialFilter formData={marketplaceAdFilterFormData} setFormData={setMarketplaceAdFilterFormData} />
                </div>
                <div className="col-md-4 mb-3 d-flex align-content-stretch justify-content-end">
                    {isFilterExpanded === true &&
                        <div className="text-primary d-flex align-items-center fs-4 cursor-pointer me-3" onClick={toggleIsFilterExpanded}>
                            <BootstrapIcon iconName="funnel" className="me-2" />
                            <span className="me-1">Filter</span>
                            <BootstrapIcon iconName="caret-up-fill" />
                        </div>
                    }
                    {isFilterExpanded === false &&
                        <div className="d-flex align-items-center fs-4 cursor-pointer me-3" onClick={toggleIsFilterExpanded}>
                            <BootstrapIcon iconName="funnel" className="me-2" />
                            <span className="me-1">Filter</span>
                            <BootstrapIcon iconName="caret-down-fill" />
                        </div>
                    }
                    <Sorting />
                </div>
            </div>
            {isFilterExpanded === true &&
                <Card cardType="shadow">
                    <Filter
                        formData={marketplaceAdFilterFormData}
                        setFormData={setMarketplaceAdFilterFormData}
                        onSubmit={onFilterSubmit}
                    />
                </Card>
            }
            {/*TODO implement*/}
        </div>
    );
}

export default MarketplaceAdOverviewPage;

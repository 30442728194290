import FormError from '../../../Entity/Form/FormError';
import FieldProps from './FieldProps';
import FieldLabel from './FieldLabel';
import React from 'react';
import Select, {ActionMeta, Options, SingleValue} from 'react-select';

interface SelectFieldProps<Option> extends FieldProps {
    readonly options: Options<Option>;
    readonly placeholder: React.ReactNode;
    readonly value: SingleValue<Option> | undefined;
    readonly onChange: (newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => void;
    readonly defaultValue?: SingleValue<Option>;
    readonly isClearable?: boolean;
}

const SelectField = <Option = unknown>(props: SelectFieldProps<Option>): React.JSX.Element => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;

    return (
        <>
            {props.label !== undefined &&
                <FieldLabel label={props.label} htmlFor={props.name} required={props.required} description={props.description} className="mb-2 ps-2" />
            }
            <Select
                name={props.name}
                options={props.options}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                defaultValue={props.defaultValue}
                required={props.required}
                isClearable={props.isClearable}
                className={['inset-shadow', hasErrors === true ? 'is-invalid': ''].join(' ')}
            />
            {hasErrors === true &&
                <>
                    {props.formErrors!.map((formError: FormError, index: number): React.JSX.Element => (
                        <div key={'select_error_' + props.name + '_' + index} className="invalid-feedback d-block">
                            {formError.message}
                        </div>
                    ))}
                </>
            }
        </>
    );
};

export default SelectField;

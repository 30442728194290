import LocationService from '../../Location/LocationService';
import MarketplaceAdFilter from '../../Entity/Marketplace/MarketplaceAdFilter';
import Country from '../../Entity/Location/Country';
import Place from '../../Entity/Location/Place';
import FormData from '../../Entity/Form/FormData';
import SelectOption from '../../Entity/Form/SelectOption';
import UserState from '../../Entity/User/UserState';
import AutocompletePlace from '../../Entity/Location/AutocompletePlace';
import PlaceSearchRadius, {getPlaceSearchRadiusLabel} from '../../Entity/Location/PlaceSearchRadius';
import SelectField from '../../Component/Form/Field/SelectField';
import InputField from '../../Component/Form/Field/InputField';
import AsyncSelectField from '../../Component/Form/Field/AsyncSelectField';
import SwitchField from '../../Component/Form/Field/SwitchField';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import {useAppSelector} from '../../../../app/hooks';
import React, {useEffect, useState} from 'react';

interface FilterProps {
    readonly formData: FormData<MarketplaceAdFilter>;
    readonly setFormData: (formData: FormData<MarketplaceAdFilter>) => void;
    readonly onSubmit: () => void;
}

const locationService: LocationService = new LocationService(process.env.REACT_APP_LLASM_API_URL!);

const placeSearchRadiusSelectOptions: SelectOption<PlaceSearchRadius | null>[] = [
    {label: 'Bitte wählen', value: null},
    {label: getPlaceSearchRadiusLabel(PlaceSearchRadius.Ten), value: PlaceSearchRadius.Ten},
    {label: getPlaceSearchRadiusLabel(PlaceSearchRadius.Twenty), value: PlaceSearchRadius.Twenty},
    {label: getPlaceSearchRadiusLabel(PlaceSearchRadius.Forty), value: PlaceSearchRadius.Forty},
];

const Filter = (props: FilterProps): React.JSX.Element => {
    const [marketplaceAdFilter, setMarketplaceAdFilter] = useState<MarketplaceAdFilter>(props.formData.data);

    const [countryPlaces, setCountryPlaces] = useState<Place[]>();

    const [countryPlaceSelectOptions, setCountryPlaceSelectOptions] = useState<SelectOption<Place>[]>();

    const [countryPlaceSelectedOption, setCountryPlaceSelectedOption] = useState<SelectOption<Place>>();

    const {user}: UserState = useAppSelector<UserState>(state => state.user);

    useEffect((): void => {
        if (countryPlaces !== undefined) {
            return;
        }

        fetchCountryPlaces();
    }, []);

    useEffect((): void => {
        if (countryPlaces === undefined) {
            return;
        }

        if (user === undefined) {
            return;
        }

        const countryPlaceSelectOptions: SelectOption<Place>[] = countryPlaces.map((place: Place): SelectOption<Place> => {
            return {
                label: place.placeName,
                value: place
            };
        });

        setCountryPlaceSelectOptions(countryPlaceSelectOptions);

        if (user.country.countryPlace !== null) {
            countryPlaceSelectOptions.forEach((countryPlaceSelectOption: SelectOption<Place>): void => {
                if (countryPlaceSelectOption.value.id === user.country.countryPlace!.id) {
                    setCountryPlaceSelectedOption(countryPlaceSelectOption);
                }
            });
        } else {
            countryPlaceSelectOptions.forEach((countryPlaceSelectOption: SelectOption<Place>): void => {
                if (countryPlaceSelectOption.value.placeShortName === 'DE') {
                    setCountryPlaceSelectedOption(countryPlaceSelectOption);
                }
            });
        }
    }, [countryPlaces, user]);

    const fetchCountryPlaces = async (): Promise<void> => {
        const countries: Country[] =  await locationService.fetchCountries(['DE', 'AT']);

        const countryPlaces: Place[] = countries.map((country: Country): Place => {
            if (country.countryPlace === null) {
                throw new Error('Country must have a country place!');
            }

            return country.countryPlace;
        });

        setCountryPlaces(countryPlaces);
    };

    const fetchCitySelectOptions = async (searchTerm: string): Promise<SelectOption<AutocompletePlace>[]> => {
        if (countryPlaceSelectedOption === undefined) {
            return [];
        }

        if (searchTerm === '' || searchTerm.length < 3) {
            return [];
        }

        return (await (locationService.fetchPlacesBySearchTerm(searchTerm, undefined, countryPlaceSelectedOption.value.uuid))).map((autocompletePlace: AutocompletePlace): SelectOption<AutocompletePlace> => {
            return {
                label: autocompletePlace.autoCompleteDisplay,
                value: autocompletePlace
            };
        });
    };

    const fetchCitySelectedOption = (): SelectOption<AutocompletePlace> | undefined => {
        if (marketplaceAdFilter.city === null) {
            return undefined;
        }

        const autocompletePlace: AutocompletePlace = new AutocompletePlace();

        autocompletePlace.autoCompleteDisplay = marketplaceAdFilter.city.placeName;
        autocompletePlace.place = marketplaceAdFilter.city;

        return {
            label: autocompletePlace.autoCompleteDisplay,
            value: autocompletePlace
        };
    };

    const fetchPlaceSearchRadiusSelectedOption = (): SelectOption<PlaceSearchRadius | null> | undefined => {
        return placeSearchRadiusSelectOptions.find((selectOption: SelectOption<PlaceSearchRadius | null>): boolean => {
            return marketplaceAdFilter.placeSearchRadius === selectOption.value;
        });
    };

    const handleCountryPlacesChange = (selectedCountry: SelectOption<Place> | null): void => {
        if (selectedCountry === null) {
            return;
        }

        const selectedCountryPlace: Place = selectedCountry.value;

        const countryPlaceSelectedOption: SelectOption<Place> = {
            label: selectedCountryPlace.placeName,
            value: selectedCountryPlace
        };

        setCountryPlaceSelectedOption(countryPlaceSelectedOption);

        marketplaceAdFilter.city = null;

        updateFormData();
    };

    const handlePlaceSearchRadiusChange = (selectedPlaceSearchRadius: SelectOption<PlaceSearchRadius | null> | null): void => {
        if (selectedPlaceSearchRadius === null) {
            marketplaceAdFilter.placeSearchRadius = null;
        } else {
            marketplaceAdFilter.placeSearchRadius = selectedPlaceSearchRadius.value;
        }

        updateFormData();
    };

    const handleCityChange = (selectedCity: SelectOption<AutocompletePlace> | null): void => {
        if (selectedCity === null) {
            marketplaceAdFilter.city = null;
        } else {
            marketplaceAdFilter.city = selectedCity.value.place;
        }

        updateFormData();
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        (marketplaceAdFilter as any)[event.target.name] = event.target.checked;

        updateFormData();
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
        switch (event.target.name) {
            case 'totalAreaSizeFrom':
                if (event.target.value === '') {
                    marketplaceAdFilter.totalAreaSizeFrom = null;
                } else {
                    marketplaceAdFilter.totalAreaSizeFrom = Number(event.target.value);
                }

                break;
            case 'totalAreaSizeTo':
                if (event.target.value === '') {
                    marketplaceAdFilter.totalAreaSizeTo = null;
                } else {
                    marketplaceAdFilter.totalAreaSizeTo = Number(event.target.value);
                }

                break;
            default:
                (marketplaceAdFilter as any)[event.target.name] = event.target.value;

                break;
        }

        updateFormData();
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: marketplaceAdFilter});
    };

    if (countryPlaceSelectOptions === undefined) {
        return <LoadingIndicator />;
    }

    return (
        <div className="p-3">
            <h2>Filter</h2>
            <div className="row">
                <div className="col-12 col-md-6 mb-3">
                    <SelectField
                        label="Land"
                        name="countryPlaces"
                        options={countryPlaceSelectOptions}
                        required={false}
                        value={countryPlaceSelectedOption}
                        placeholder="Bitte wählen"
                        onChange={handleCountryPlacesChange}
                    />
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <InputField
                        name="totalAreaSizeFrom"
                        label="Gesamtfläche von"
                        description="Gib hier die Minimalgröße der Fläche ein, die Du suchst."
                        type="number"
                        suffix="m²"
                        min={0}
                        required={false}
                        value={marketplaceAdFilter.totalAreaSizeFrom !== null ? marketplaceAdFilter.totalAreaSizeFrom : undefined}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <InputField
                        name="totalAreaSizeTo"
                        label="Gesamtfläche bis"
                        description="Gib hier an, wie groß die Gesamtfläche des Objekts, das Du suchst maximal sein darf."
                        type="number"
                        suffix="m²"
                        min={0}
                        required={false}
                        value={marketplaceAdFilter.totalAreaSizeTo !== null ? marketplaceAdFilter.totalAreaSizeTo : undefined}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <AsyncSelectField
                        label="Ort"
                        name="city"
                        required={false}
                        loadOptions={fetchCitySelectOptions}
                        defaultOptions
                        closeMenuOnSelect={false}
                        placeholder="Ort suchen"
                        loadingMessage={(): string => 'Passende Orte werden gesucht...'}
                        onChange={handleCityChange}
                        value={fetchCitySelectedOption()}
                        isClearable={true}
                    />
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <SelectField
                        label="Umkreis"
                        name="placeSearchRadius"
                        options={placeSearchRadiusSelectOptions}
                        required={false}
                        value={fetchPlaceSearchRadiusSelectedOption()}
                        placeholder="Bitte wählen"
                        onChange={handlePlaceSearchRadiusChange}
                    />
                </div>
                <div className="col-12 col-md-3 mb-3 pt-3">
                    <SwitchField
                        label="nur sofort verfügbare Flächen"
                        description="Wähle diese Option, wenn Du nur Angebote angezeigt bekommen möchtest, die sofort zur Verfügung stehen."
                        selected={marketplaceAdFilter.onlyImmediatelyAvailable}
                        name="onlyImmediatelyAvailable"
                        onChange={handleSwitchChange}
                    />
                </div>
                <div className="col-12 col-md-3 mb-3 d-flex align-items-end justify-content-end">
                    <button
                        className="btn btn-primary d-flex align-items-center mt-3"
                        type="button"
                        onClick={props.onSubmit}>
                        Filter anwenden
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Filter;

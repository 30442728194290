import MarketplaceService from '../MarketplaceService';
import MarketplaceLot from '../../Entity/Marketplace/MarketplaceLot';
import InfoButtonOverlay from '../../Component/InfoButtonOverlay/InfoButtonOverlay';
import Spinner from '../../../../components/Spinner';
import {useAppDispatch} from '../../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

const marketplaceService = new MarketplaceService(process.env.REACT_APP_LLASM_API_URL!);

const MarketplaceLotOverviewPage = (): React.JSX.Element => {
    const [marketplaceLots, setMarketplaceLots] = useState<MarketplaceLot[]>();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Marktplatz', link: '/marktplatz'},
                {name: 'Meine Inserate'},
            ]
        });

        fetchMarketplaceLots();
    }, []);

    const fetchMarketplaceLots = async (): Promise<void> => {
        setMarketplaceLots(await marketplaceService.fetchMarketplaceLotsFromApi());
    };

    if (process.env.NODE_ENV === 'production') {
        return <></>;
    }

    if (marketplaceLots === undefined) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid">
            <div className="row align-items-center mb-4">
                <div className="col-12 col-md-6 d-flex align-items-center">
                    <Link to="/marktplatz"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Meine Inserate</h1>
                </div>
                <div className="col-md-6 d-none d-md-flex align-items-center justify-content-end px-4">
                    <InfoButtonOverlay title="Meine Inserate" className="fs-5 ms-1 me-3">
                        <div className="mb-3">
                            Um Flächen auf unserem Marktplatz zu veröffentlichen, brauchst Du „digitale Stellplätze". Ein
                            „digitaler Stellplatz" hat eine feste Laufzeit. Die Laufzeit verringert sich nur, wenn ein Objekt mit
                            dem Stellplatz verknüpft ist.
                        </div>
                        <div className="mb-3">
                            Auf dieser Seite kannst Du Stellplätze erwerben und von Dir erfasste Objekte mit den digitalen
                            Stellplätzen verknüpfen oder auch wieder vom Stellplatz entfernen.
                        </div>
                    </InfoButtonOverlay>
                </div>
            </div>
            {/*TODO implement*/}
        </div>
    );
}

export default MarketplaceLotOverviewPage;

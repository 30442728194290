import MatchPointService from './MatchPointService';
import OrderStep from '../../shared/Entity/MatchPoint/OrderStep';
import User from '../../shared/Entity/User/User';
import BillingAddress from '../../shared/Entity/User/BillingAddress';
import PurchaseMethod from '../../shared/Entity/Payment/PurchaseMethod';
import Purchase from '../../shared/Entity/MatchPoint/Purchase';
import Currency from '../../shared/Entity/Currency';
import PaymentWrapper from './PaymentWrapper';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

interface InvoicePaymentProps {
    readonly matchPointService: MatchPointService;
    readonly setOrderStep: (orderStep: OrderStep) => void;
    readonly user: User;
    readonly numberFormat: Intl.NumberFormat;
    readonly currency: Currency;
    readonly taxRate: number;
    readonly amount: number
    readonly priceNet: number;
    readonly priceGross: number;
    readonly billingAddress: BillingAddress;
}

const InvoicePayment = (props: InvoicePaymentProps): React.JSX.Element => {
    const [purchase, setPurchase] = useState<Purchase>();

    const [paymentInProgress, setPaymentInProgress] = useState<boolean>(false);

    useEffect((): void => {
        if (purchase !== undefined) {
            props.setOrderStep(OrderStep.InvoicePaymentOrderConfirmation);
        }
    }, [purchase]);

    const doPurchase = async ():Promise<void> => {
        setPurchase(await props.matchPointService.purchaseMatchPoint(
            props.amount,
            props.priceNet,
            PurchaseMethod.BankTransfer,
            props.billingAddress
        ));
    };

    const submit = async (): Promise<void> => {
        setPaymentInProgress(true);

        await doPurchase();

        setPaymentInProgress(false);
    };

    return (
        <div className="container-fluid pb-4">
            <div className="d-flex align-items-center mb-3">
                <Link to="" onClick={() => props.setOrderStep(OrderStep.OrderOverview)}>
                    <i className="bi bi-arrow-left-short text-secondary fs-lg"></i>
                </Link>
                <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Kauf abschließen</h1>
            </div>
            <PaymentWrapper
                user={props.user}
                numberFormat={props.numberFormat}
                currency={props.currency}
                taxRate={props.taxRate}
                amount={props.amount}
                priceNet={props.priceNet}
                priceGross={props.priceGross}
                billingAddress={props.billingAddress}
                paymentSubmitHandler={submit}
                paymentInProgress={paymentInProgress}
                purchaseMethod={PurchaseMethod.BankTransfer}
                paymentMethodText="Auf Rechnung"
            >
                <div className="fs-5 mb-5">
                    Bitte bestätige den Kauf über den untenstehenden Button.
                    Im Anschluss erhältst Du eine Bestellbestätigung per E-Mail.
                    Deine Rechnung wird Dir von unserer Buchhaltung zugesandt.
                    Danach werden Dir die MatchPoints gutgeschrieben.
                    Bitte beachte, dass dieser Prozess in der Regel innerhalb von 2–3 Stunden abgeschlossen ist.
                    In Ausnahmefällen kann die Bearbeitung jedoch bis zu 48 Stunden dauern.
                    Du wirst in jedem Fall per E-Mail informiert.
                </div>
            </PaymentWrapper>
        </div>
    );
};

export default InvoicePayment;

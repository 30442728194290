import FormError from '../../../Entity/Form/FormError';
import FieldProps from './FieldProps';
import FieldLabel from './FieldLabel';
import FieldStyle from './Field.module.scss';
import AsyncSelectFieldStyle from './AsyncSelectField.module.scss';
import React from 'react';
import AsyncSelect from 'react-select/async';
import { SingleValue, ActionMeta, GroupBase, OptionsOrGroups } from 'react-select';

interface AsyncSelectFieldProps<Option, Group extends GroupBase<Option>> extends FieldProps {
    readonly placeholder: React.ReactNode;
    readonly value?: SingleValue<Option>;
    readonly closeMenuOnSelect: boolean;
    readonly onChange: (newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => void;
    readonly loadOptions?: (inputValue: string, callback: (options: OptionsOrGroups<Option, Group>) => void) => Promise<OptionsOrGroups<Option, Group>> | void;
    readonly defaultOptions?: OptionsOrGroups<Option, Group> | boolean;
    readonly loadingMessage?: (obj: { inputValue: string; }) => React.ReactNode;
    readonly isClearable?: boolean;
}

const AsyncSelectField = <Option = unknown, Group extends GroupBase<Option> = GroupBase<Option>>(props: AsyncSelectFieldProps<Option, Group> & React.RefAttributes<SingleValue<Option>>): React.JSX.Element => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;

    return (
        <>
            {props.label !== undefined &&
                <FieldLabel label={props.label} htmlFor={props.name} required={props.required} description={props.description} className="mb-2 ps-2" />
            }
            <div className={[FieldStyle.container, AsyncSelectFieldStyle.asyncSelectField, hasErrors === true ? ' is-invalid' : '', props.className].join(' ')}>
                <AsyncSelect
                    name={props.name}
                    placeholder={props.placeholder}
                    value={props.value}
                    closeMenuOnSelect={props.closeMenuOnSelect}
                    onChange={props.onChange}
                    loadOptions={props.loadOptions}
                    defaultOptions={props.defaultOptions}
                    loadingMessage={props.loadingMessage}
                    className={AsyncSelectFieldStyle.asyncSelectComponent}
                    isClearable={props.isClearable}
                />
            </div>
            {hasErrors === true &&
                <>
                    {props.formErrors!.map((formError: FormError, index: number): React.JSX.Element => (
                        <div key={'select_error_' + props.name + '_' + index}  className="invalid-feedback d-block">
                            {formError.message}
                        </div>
                    ))}
                </>
            }
        </>
    );
};

export default AsyncSelectField;
